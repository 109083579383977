import store from '@tenant/core/init/store'
import { GUARD, USER_ACCOUNT_TYPES } from '@tenant/utils/constants'

const tenantGuard = async (to, from, next) => {
    if (
        to.meta.guard === GUARD.TENANT ||
        to.meta.guard?.includes(GUARD.TENANT)
    ) {
        if (!store.getters.hasTenantToken && to.name !== 'auth.invite-logged') {
            if (store.getters.hasCentralRole(USER_ACCOUNT_TYPES.ACCOUNTANT)) {
                next({ name: 'accountant.clients' })

                return
            }

            next({ name: 'auth.login-company' })

            return
        }

        if (
            store.getters.expiredSubscription &&
            !to.meta.guard.includes(GUARD.SUBSCRIPTION)
        ) {
            next({ name: 'subscriptions.change' })

            return
        }

        const acl = useAcl()
        if (
            !to.name.includes('onboarding') &&
            !to.name.includes('home') &&
            !acl.can(to.meta?.permission) &&
            ![GUARD.LINK, GUARD.GUEST].includes(to.meta?.guard)
        ) {
            return next({ name: 'home.index' })
        }
    }

    next()
}

export default tenantGuard
